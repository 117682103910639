<template>
    <div>
        <div class="range flex column center">
            <div class="title1 wow slideInLeft">
                <p class="title_top">OUR BUSINESS</p>
                <p class="title_content">鉴定范围</p>
            </div>
            <div style="margin:0 auto">

                <div class="flex wrap" style="wdith:80%;margin: 0 10%">
                    <div class="wow bounceIn flex center col2" @click="goRange(item.name)" style="height:20vw;min-height:250px;max-height:300px;" :key="item.url" v-for="item in rangeData">
                        <div  style="width:95%;position:relative;height:70%" >
                            <img class="inherit box_shadow" v-lazy="require('../../assets/home/'+item.url)">
                            <p class="bottom_text">{{item.name}}</p>
                        </div>
                    </div>
                    <div class="wow bounceIn flex center evn" @click="goRange('环境损害鉴定')"  >
                        <div  style="width:100%;padding: 0 1%;position:relative;" >
                            <img class="inherit box_shadow" v-lazy="require('../../assets/home/1-11.jpg')">
                            <p class="bottom_text">环境损害鉴定</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="title1 wow slideInLeft">
                <p class="title_top">ABOUT US</p>
                <p class="title_content">鉴定所简介</p>
            </div>
            <div class=" wow bounceInRight flex column center" style="width:100%;">
                <img class="col8 pointer" @click="go('/about/introduce')" style="z-index:2" v-lazy="require('../../assets/home/2-1.jpg')">
                <div class="bg2 flex center">
                    <div class="col8" style="height:100%">
                        <div style="" class="flex wrap box" >
                            <div class="text_box flex column center">
                                <p>
                                    <span style="font-size:2rem;">500</span>
                                    <span style="font-size:1.3rem;">余家</span>

                                </p>
                                <p>公检司法用户</p>
                            </div>
                            <div class="text_box flex column center">
                                <p>
                                    <span style="font-size:2rem;">40</span>
                                    <span style="font-size:1.3rem;">余人</span>

                                </p>
                                <p>各专业优秀鉴定专家团队</p>
                            </div>
                            <div class="text_box flex column center">
                                <p>
                                    <span style="font-size:1.3rem;">年</span>
                                    <span style="font-size:2rem;">2000</span>
                                    <span style="font-size:1.3rem;">余件</span>

                                </p>
                                <p>案件受理量</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="title1 wow slideInLeft">
                <p class="title_top">OUR ADVANTAGEE</p>
                <p class="title_content">我们的优势</p>
            </div>
            <div class="col8 flex wrap advantage">
                <div class="col3 column height around1 flex">
                    <div class="content_box col9 wow bounceInLeft">
                        <p class="title">鉴定资质齐全</p>
                        <p class="content">持有17大项执业资质： 法医物证鉴定（DNA亲权鉴定、个体识别）、法医临床鉴定、法医毒物鉴定、法医病理鉴定、环境损害鉴定（含7大项）、微量物证鉴定、文书物证鉴定、痕迹物证鉴定、电子数据鉴定、录音鉴定、图像鉴定等。</p>
                    </div>
                    <div class="content_box col9 wow bounceInLeft">
                        <p class="title">覆盖地域广阔</p>
                        <p class="content">目前历思已服务海南、江苏、湖南、辽宁等近20个省份。</p>
                    </div>
                </div>
                <div class="col3 height around1 flex center">
                    <img v-lazy="require('../../assets/home/2-2.png')" class="box2" >
                </div>
                <div class="col3 column height around1 flex">
                    <div class="content_box col9 wow bounceInLeft">
                        <p class="title">顶级实验设备</p>
                        <p class="content">实验室全面配置了Waters、Thermo Fisher、AB公司、foster + freeman、基恩士、奥林巴斯等生产的最新型进口液相、液质联用、ICP-MS、离子色谱、基因测序仪、超快速拉曼成像光谱仪、声像工作站、超级文检工作站、超景深三维立体显微系统、X射线荧光光谱仪、纳克级快速精准质谱仪等高端检验设备，走在了行业前沿。</p>
                    </div>
                    <div class="content_box col9 wow bounceInLeft">
                        <p class="title">专业鉴定团队</p>
                        <p class="content">拥有40余位各专业优秀鉴定专家，其中有多位博士、硕士及高级职称的专业人员。</p>
                    </div>
                </div>
            </div>
            <div class="title1 wow slideInLeft">
                <p class="title_top">IDENTIFY PROCESS</p>
                <p class="title_content">鉴定流程</p>
            </div>
            <div class="col8 wow bounceInRight">
                <ImagePreview :src="src" :srcList="[src]"></ImagePreview>
            </div>
            <div class="title1 wow slideInLeft">
                <p class="title_top">NEW</p>
                <p class="title_content">中心动态</p>
            </div>
            <!-- <el-carousel class="col8 carousel wow flipInX" style="background:#fff;"   :autoplay="true">
                <el-carousel-item  v-for="(_,index) in Math.ceil(activeData.length/column)" :key="index">
                    <div style="padding:10px;height:100%" class="flex">
                        <div class="col3" @click="go(activeData[index*column+i].path)" v-for="(_,i) in column"  :key="i">
                            <Item :data="activeData[index*column+i]" v-if="activeData[index*column+i]"></Item>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel> -->
            <el-carousel type="card" class="col8 carousel wow flipInX" style="background:#fff;"   :autoplay="true">
                <el-carousel-item  v-for="item in activeData" :key="item.path">
                    <div style="padding:10px;height:100%;width:100%" class="flex">
                        <div  @click="go(item.path)">
                            <Item :data="item" v-if="item"></Item>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div class="title1 wow slideInLeft">
                <p class="title_top">ONLINE MESSAGE</p>
                <p class="title_content">在线留言</p>
            </div>
            <div class="col8" style="margin-bottom:2rem;background-color: rgba(242, 242, 242, 1);">
                <div style="width:90%;padding:5%;">
                    <Info></Info>
                </div>
            </div>
            <div class="col8 link_box">
                <div class="title">友情链接</div>
                <div >
                    <div style="height:3.5rem" class="col25 inline_block">
                        <div style="width:80%;margin:0 10%;height:inherit">
                            <a target="_blank" href="http://www.moj.gov.cn/">
                                <img class="box_shadow" style="width:100%;max-width: 293px;height:100%" v-lazy="require('../../assets/home/4-2.png')">
                            </a>
                        </div>
                    </div>
                    <div style="height:3.5rem" class="col25 inline_block">
                        <div style="width:80%;margin:0 10%;height:inherit">
                            <a target="_blank" href="http://sf.xm.gov.cn/">
                                <img class="box_shadow" style="width:100%;max-width: 293px;height:100%" v-lazy="require('../../assets/home/4-3.png')">
                            </a>
                        </div>
                    </div>
                    <div style="height:3.5rem" class="col25 inline_block">
                        <div style="width:80%;margin:0 10%;height:inherit">
                            <a target="_blank" href="http://www.lisitech.com/">
                                <img class="box_shadow" style="width:100%;max-width: 293px;height:100%" v-lazy="require('../../assets/home/4-1.jpg')">
                            </a>

                        </div>
                    </div>
                    <div style="height:3.5rem" class="col25 inline_block">
                        <div style="width:80%;margin:0 10%;height:inherit">
                            <a target="_blank" href="https://www.kunming-biotech.com/">
                                <img class="box_shadow" style="width:100%;max-width: 293px;height:100%" v-lazy="require('../../assets/home/4-4.jpg')">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import Item from "@/components/columnItem.vue"
import Info from "@/components/Info.vue"
import src from '@/assets/home/2-3.png'
export default {
    components:{Item,Info},
    data(){
        return{
            column:3,

            rangeData:[


                {url:'1-2.jpg',name:'法医临床鉴定'},
                {url:'1-3.png',name:'法医物证鉴定'},
                {url:'1-4.jpg',name:'法医毒物鉴定'},
                {url:'1-1.png',name:'法医病理鉴定'},
                {url:'1-5.jpg',name:'文书鉴定'},
                {url:'1-6.png',name:'痕迹鉴定'},
                {url:'1-7.jpg',name:'微量物证鉴定'},
                {url:'1-8.jpg',name:'录音鉴定'},
                {url:'1-9.png',name:'图像鉴定'},
                {url:'1-10.jpg',name:'电子数据鉴定'},
                // {url:'1-11.png',name:'环境损害鉴定'},
            ],
            activeData:[
                {path:'/news/lsnews/2',url:'home/3-1.jpg',title:'【官宣】“污水验毒”专用智慧采样器，硬核上线啦~',content:"好消息！厦门历思科技服务有限公司 旗下的厦门堃铭生物技术有限公司又出了一“硬核设备”“污水验毒”专用智慧采样器——智慧水质采样器 LS-WS20OF01"},
                // {path:'/news/lsnews/3',url:'home/3-2.jpg',title:'福建省、市、区禁毒委领导莅临福建历思司法鉴定所调研指导',content:"2019年10月30日，福建省禁毒委副主任、省公安厅党委委员薛祺安 ，福建省禁毒办副主任、省公安厅禁毒总队总队长吴福顺，厦门市禁毒委副主任、市公安局党委委员、副局长林传灿，厦"},
               {path:'/news/lsnews/3',url:'news/ls/3-1.png',title:'福建省、市、区禁毒委领导莅临福建历思司法鉴定所调研指导',
                 content:"11月22日，福建省创新研究院中科育成中心主任冯先伟一行莅临厦门历思科技服务有限公司（以下简称历思）开展工作调研，了解历思发展规划和技术需求。历思领导向冯主任介绍了公司的基本情况。历思是一家拥有多专业物证优势技术的国家级高新技术企业，目前历思旗下有3家司法鉴定所、1家检测集团公司、1家生物科技公司、" +
                     "1家价值评估公司。历思自主研发的多类产品已申报并获批多项国内、国际发明专利（主要包含美国、日本、欧盟）与实用新型专利。下一阶段，历思将在毒品新型检测平台等核心产品研发上持续加大投入，力求做出完全具有自主知识产权的新产品，取得行业领先的原创成果。冯主任对历思取得的成绩表示祝贺，" +
                     "并介绍了福建省创新研究院中科育成中心的战略定位、业务发展方向等。他表示，希望以本次工作调研为契机，探索双方的合作研发方式，为历思匹配中科院研究所的相关技术资源，进一步提升企业产品的创新能力，从而拓宽发展思路。" +
                     "调研期间，双方就新一代涉毒物质检验设备的关键技术攻关进行了深入探讨，希望双方发挥各自优势，推动企业的技术创新和产业发展。"
               },
                {path:'/news/lsnews/1',url:'home/3-3.jpg',title:'全国首例！历思助力技术调查官参审污染环境案件，宣判啦~',content:"近日，福建某法院对被告人黄某污染环境罪一案依法公开开庭审理并当庭宣判。 两名生态环境技术调查官首次出席参加庭审，成为全国首起 在生态环境诉讼中引入技术调查官制度的案件。"},
                // {path:'/news/industrynews/1',url:'home/3-4.png',title:'2022司法鉴定轻伤标准是怎样的',content:"在自己的人身受到伤害的时候，我们需要向他人索赔的话，就需要对自己的伤情进行鉴定，如果是轻伤的话，就要按照轻伤的标准去鉴定，一旦达到轻伤的标准我们就应当去维护自己的权益。那么，接下来由找法网小编为大家带有关于2022司法鉴定轻伤标准是怎样的的知识吧，以供大家参考！"},
                // {path:'/news/industrynews/2',url:'home/3-5.png',title:'司法鉴定委托书如何签定',content:"在很多案件中，证据都是起决定性作用的。法院一般不会采纳来源不合法的证据，真正的证据是需要经过专业科学认定的，也就是司法鉴定。司法鉴定委托他人提出的，需要有一份委托书来委托他人代替自己办理。那么司法鉴定委托书如何签定？下文找法网小编为您整理了一些相关资料，以供参考。"},
            ],
            src:src,
            rangePathData:[]
        }
    },
    created(){
        let rangePathData = this.$router.options.routes[1].children.filter(item=>item.path == 'range')[0].children
        console.log(rangePathData)
        this.rangePathData = rangePathData

        let width = window.innerWidth;
        console.log(width)
        if(width<633){
            this.column = 1
        }
        else if(width<=570){
            this.column = 2
        }else{
            this.column = 3
        }
        window.onresize = function(){
            let width = window.innerWidth;
            if(width<633){
                this.column = 1
            }
            else if(width<570){
                this.column = 2
            }else{
                this.column = 3
            }
        }
    },
    methods:{
        goRange(title){
            let path = this.rangePathData.filter(item=>item.meta.title == title)[0].path
            this.$router.push({
                path:'/range/'+path,
                query:{}
            })
        },
        go(path){
           this.$router.push({
                path:path,
                query:{}
            })
        }
    }
}
</script>
<style scoped>
.range .bottom_text{
    position: absolute;
    bottom: 6%;
    left: 2.5%;
    width: inherit;
    background: rgb(161 161 161 / 55%);
    line-height: 3rem;
    color: #fff;
}
.range .bottom_text:hover{
    background:rgb(47 139 193 / 55%);
    cursor: pointer;
}
.range .evn .bottom_text{
    bottom: 2%;
    left: 1%;
    width: 98%;
}
.bg2{
    width: 100%;
    background: rgba(187, 165, 117, 1);
    color: #fff;
    height: 24rem;
    margin-top: -13rem;
}
.bg2 .box{
    height:11rem;margin-top:13rem;
}
.bg2 .text_box{
    /* margin-top: 13rem;
    height:11rem; */
    height:inherit;
    width:33.3%;
}
.advantage .height{
    height:500px;
}
.advantage .box2{
    height: 348px;
    min-width: 300px;
}
.advantage .content_box{
    text-align: left;
    margin: 0 auto;
}
.advantage .title{
    font-size:1.8rem;
    margin: 0 auto;
    width: fit-content;
}
.advantage .content{
    max-width:300px;
    margin: 0 auto;
}
@media screen and (max-width:400px){
    .bg2{
        height: 22rem;
        margin-top: -10rem;
    }
    .bg2 .box{
        height:11rem;margin-top:10rem;
    }
}
@media screen and (max-width:1100px){
    .advantage .content_box{
        text-align:center;
        margin:0 auto;
    }
}
/deep/.el-carousel__container{
    max-height:600px;
    min-height:400px;
}
.link_box{
    border:1px solid;
    margin-bottom:2rem;
    position:relative;
    padding: 2rem 0;
    text-align: left;
}
.link_box .title{
    position: absolute;
    top: -1rem;
    background: #fff;
    padding: 0 1rem;
    left: calc(50% - 48px);
    font-weight: bold;
}
</style>
