<template>
    <div style="text-align:left;">
        <div class="col3 inline_block">
            <div style="width:90%">
                <p class="title">姓名</p>
                <el-input placeholder="请输入姓名" v-model="form.name"></el-input>
            </div>
        </div>
        <div class="col3 inline_block">
            <div style="width:90%">
                <p class="title">邮箱</p>
                <el-input placeholder="请输入邮箱" v-model="form.emial"></el-input>
            </div>
        </div>
        <div class="col3 inline_block">
            <div style="width:90%">
                <p class="title">电话</p>
                <el-input placeholder="请输入电话" v-model="form.phone"></el-input>
            </div>
        </div>
        <p class="title">内容</p>
        <el-input
        type="textarea"
        :rows="10"
        placeholder="请输入内容"
        v-model="form.info_content">
        </el-input>
        <div class="flex center">
            <el-button type="primary" style="margin:1rem 2rem; width:100px;">提交</el-button>
            <el-button style="margin:1rem 2rem; width:100px;" @click="form={}">重置</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            form:{},
        }
    }   
}
</script>
<style scoped>
.title{
    line-height:4rem;
}
</style>